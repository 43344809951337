<template lang="pug">
    div Hola
        div(v-html="html")


</template>

<script>
/* eslint-disable */
export default {
    name: 'Exam',
    components: {
    },
    data() {
        return {
            html: '<p>Loading...</p>'
        }
    },
    methods: {
    },
    mounted() {
        this.checkPermisions(this.$router.currentRoute.path)
        this.$http.get('http://108.175.5.101:8083/practice.html').then(response => {
            console.log('Listo')
            this.html = response.data
        });
    }
}
</script>
